// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/usr/src/app/node_modules/.pnpm/@umijs+runtime@3.5.43_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
    "title": "NeeMoo",
    "exact": true
  },
  {
    "path": "/statistics",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__statistics__home' */'@/pages/statistics/home'), loading: LoadingComponent}),
    "title": "Neemoo Coffee 选址数据",
    "exact": true
  },
  {
    "path": "/statistics/points",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__statistics__points' */'@/pages/statistics/points'), loading: LoadingComponent}),
    "title": "Neemoo Coffee 点位数据",
    "exact": true
  },
  {
    "path": "/statistics/personInCharge",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__statistics__personInCharge' */'@/pages/statistics/personInCharge'), loading: LoadingComponent}),
    "title": "Neemoo Coffee 负责人数据",
    "exact": true
  },
  {
    "path": "/*",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
    "title": "NOT FOUND",
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
